'use client'

import { useRouter } from 'next/navigation'
import type { PostListInfiniteItem } from '../../types'
import styles from './PostList.module.scss'
import iconFirstGachi from '@/assets/images/icons/icon-grid-first-gachi.png'
import iconNewComer from '@/assets/images/icons/icon-grid-new-comer.png'
import FaIcon, { faHeart } from '@/components/icon/FaIcon/FaIcon'
import { formatCurrency } from '@/utils/formatters'

function PostList({ posts }: { posts: PostListInfiniteItem[] }) {
  const router = useRouter()
  const goPostDetail = (hashKey: string) => {
    router.push(`/posts/${hashKey}`)
  }
  // 枠色を出し分ける関数
  const getBorderClass = (post: PostListInfiniteItem) => {
    if (post.artist.isFirstGachi === 1) {
      return styles.borderFirstGachi
    }
    if (post.artist.isNewcomer === 1) {
      return styles.borderNewcomer
    }
    return ''
  }
  // 左上アイコンを出し分ける関数
  const getLeftIcon = (post: PostListInfiniteItem) => {
    if (post.artist.isFirstGachi === 1) {
      return iconFirstGachi.src
    }
    if (post.artist.isNewcomer === 1) {
      return iconNewComer.src
    }
    return ''
  }
  return (
    <div className={styles.componentStyle}>
      {posts.map((post) => (
        // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
        <div
          key={post.hashKey}
          className={styles.postItemContainer}
          onClick={() => {
            goPostDetail(post.hashKey)
          }}
        >
          {/* 左上のアイコンを追加 */}
          <div className={styles.topLeftIconContainer}>
            {getLeftIcon(post) && (
              <img
                src={getLeftIcon(post)}
                alt='New'
                className={styles.topLeftIcon}
              />
            )}
          </div>
          <img
            src={post.imageUrl}
            alt={post.hashKey}
            className={`${styles.postImage} ${
              getBorderClass(post) && getBorderClass(post)
            }`}
          />
          <div className={styles.postInfoContainer}>
            <p className={styles.ellipsisBody}>{post.body}</p>
            <div className={styles.artistNameCheerContainer}>
              <p className={styles.artistName}>{post.artist.name}</p>
              <div className={styles.cheerIconText}>
                <FaIcon icon={faHeart} />
                <p>{formatCurrency(post.cheer)}</p>
              </div>
            </div>
          </div>
          {post.event.id && (
            <div className={styles.eventIconContainer}>
              <img
                src={post.event.iconUrl}
                alt={post.event.title}
                className={styles.eventIcon}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default PostList
