'use client'

import React from 'react'
import MediaPlayer from '../MediaPlayer/MediaPlayer'
import styles from './VideoLiveEvent.module.scss'
import OnLiveList from '@/features/live/components/OnLiveList/OnLiveList'
import { OnLiveItem } from '@/features/live/types'
import TopPostList from '@/features/post/components/TopPostList/TopPostList'
import { PostListInfiniteItem } from '@/features/post/types'

type VideoLiveEventProps = {
  lives: OnLiveItem[]
  posts: PostListInfiniteItem[]
  children?: React.ReactNode
}

const VideoLiveEvent: React.FC<VideoLiveEventProps> = ({ lives, posts }) => {
  return (
    <div className={styles.container}>
      <div className={styles.videoContainer}>
        <div className={styles.videoStyle}>
          <MediaPlayer
            src={
              process.env.NEXT_PUBLIC_S3_BASE_URL
                ? `${process.env.NEXT_PUBLIC_S3_BASE_URL}/default/top-movie/mymove.m3u8`
                : ''
            }
            muted={true}
            autoPlay={true}
            loop={true}
            maxHeight='none'
          />
        </div>
      </div>
      <div>
        {lives.length > 0 && (
          <OnLiveList lives={lives} spMaxNum={3} pcMaxNum={4} />
        )}
        <TopPostList posts={posts} />
      </div>
    </div>
  )
}

export default VideoLiveEvent
