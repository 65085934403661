'use client'

import Hls, { type HlsConfig } from 'hls.js'
import type React from 'react'
import { useRef, useEffect, useState, useCallback } from 'react'
import styles from './MediaPlayer.module.scss'

interface MediaPlayerProps {
  tag?: 'video' | 'audio'
  src: string
  thumbnailUrl?: string
  maxWidth?: string
  maxHeight?: string
  muted?: boolean
  autoPlay?: boolean
  loop?: boolean
}

const MediaPlayer: React.FC<MediaPlayerProps> = ({
  tag = 'video',
  src,
  thumbnailUrl = '',
  maxWidth = '100%',
  maxHeight = '400px',
  muted = false,
  autoPlay = false,
  loop = false,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [hls, setHls] = useState<Hls | null>(null)
  const [inited, setInited] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const initVideo = useCallback(() => {
    const video = videoRef.current
    if (Hls.isSupported() && video) {
      const config: Partial<HlsConfig> = {
        debug: process.env.NODE_ENV !== 'production',
        xhrSetup: (xhr) => {
          xhr.withCredentials = true
          xhr.setRequestHeader(
            'Access-Control-Allow-Headers',
            'Content-Type, Accept, X-Requested-With',
          )
          xhr.setRequestHeader(
            'Access-Control-Allow-Origin',
            process.env.NEXT_PUBLIC_BASE_URL as string,
          )
          xhr.setRequestHeader('Cache-Control', 'no-cache')
          xhr.setRequestHeader('Access-Control-Allow-Credentials', 'true')
        },
      }
      const hls = new Hls(config)
      hls.loadSource(src)
      hls.attachMedia(video)
      hls.on(Hls.Events.MANIFEST_PARSED, () => {})
      hls.on(Hls.Events.ERROR, (event, data) => {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            setErrorMessage(
              'ファイルを変換中です。しばらく時間を置いてから開いてください。',
            )
            break
          default:
            break
        }
      })
      setHls(hls)
    } else if (video?.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = src
    }
  }, [src])

  const init = useCallback(() => {
    if (!src || inited) {
      return
    }
    if (tag === 'video') {
      initVideo()
    }
    setInited(true)
  }, [initVideo, inited, src, tag])

  const disposePlayer = useCallback(() => {
    if (hls) {
      hls.destroy()
    }
  }, [hls])

  useEffect(() => {
    init()
    return () => {
      disposePlayer()
    }
  }, [init, disposePlayer])

  return (
    <div>
      {errorMessage && (
        <p className='text-x-small color-error'>{errorMessage}</p>
      )}
      <div className={styles.playerWrapper}>
        {tag === 'video' ? (
          // biome-ignore lint/a11y/useMediaCaption: <explanation>
          <video
            ref={videoRef}
            className={styles.video}
            controls
            poster={thumbnailUrl}
            style={{ maxWidth, maxHeight }}
            muted={muted}
            autoPlay={autoPlay}
            loop={loop}
          />
        ) : (
          // biome-ignore lint/a11y/useMediaCaption: <explanation>
          <audio
            ref={videoRef}
            className={styles.audio}
            controls
            src={src}
            style={{ maxWidth, maxHeight }}
          />
        )}
      </div>
    </div>
  )
}

export default MediaPlayer
